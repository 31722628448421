import "./gradBtn.css"
export default function GradBTN (props){    
    
    if(props.what==="link"){
        return(
            <a href={props.link} class="gradeBtn">
                <span>{props.text}</span>
                {props.image?<img src={props.image} alt={props.alt}/>:null}                
            </a>
        )
    } else{
        return(
            <button class="gradeBtn forBtn">
                <span className="gradText">{props.text}</span>
            </button>
        )
    }
}