import "./bhoke.css"

export default function Bhoke (){
    return(
        <div style={{width: "150px", height: "150px"}}>
            {/* <div style={{width: "100%", height: "100%", background: "rgb(255,94,82)", background: "radial-gradient(circle, rgba(255,94,82,0.25) 0%, rgba(0,0,0,0) 50%)"}}> */}
            {/* <img style={{width:"100%", objectFit:"contain", objectPosition:"center", mixBlendMode:"overlay"}} src="./images/bhoke.png"/> */}
            <div style={{width:"100%", height:"100%", borderRadius:"50%",backgroundColor:"#ff1200"}}></div>
        </div>
    )
}