import './contact.css';
import Marquee from '../components/marquee';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Testimonial from '../components/testimonial';
import { CustomAlert } from './Home';

export default function Contact(){
    const [enq, setEnq] = useState({
        AppStatus:'webEnq',
        name:'',
        mobile:'',
        email:'',
        web_development:'',
        web_application:'',
        app_development:'',
        logo_design:'',
        brand_design:'',
        location:''
    })

    const enqChange = (e) =>{
        const {name, value} = e.target
        if(name==='service'){
            setEnq((prevData) => ({
                ...prevData,
                [value]: value,
            }));
        }else{
            setEnq((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    }
    const enqSubmit = (e) =>{
        e.preventDefault()

        const enqData = new FormData();
        enqData.append('AppStatus', enq.AppStatus);
        enqData.append('Name', enq.name)
        enqData.append('Mobile', enq.mobile)
        enqData.append('Email', enq.email)
        enqData.append('AppStatus', enq.AppStatus)
        enqData.append('Web_development', enq.web_development)
        enqData.append('Web_application', enq.web_application)
        enqData.append('App_development', enq.app_development)
        enqData.append('Logo_design', enq.logo_design)
        enqData.append('Brand_design', enq.brand_design)

        axios.post('https://script.google.com/macros/s/AKfycbz-a-TG2qhcK0xXkmC2hHvq23b64_SJ_XvM7ZuOhoSaKTV4sOe6EpnKEfh6hX0yvXv-Og/exec', enqData)
        .then(res=>{
            if (res.status===200){
                handleShowAlert()
                setEnq({
                    AppStatus:'webEnq',
                    name:'',
                    mobile:'',
                    email:'',
                    web_development:'',
                    web_application:'',
                    app_development:'',
                    logo_design:'',
                    brand_design:'',
                    location:''
                })
            } else{
                console.error('Server returned an error status:', res.status);
            }
        })
        .catch(error=>{
            console.error('Error:',error);
        })
    }

    // touch check
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

//  Alert on Submit
    const [showAlert, setShowAlert] = useState(false);

    const handleShowAlert = () => {
        setShowAlert(true);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    //  Mouse Follower
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const followerRef = useRef(null);

    useEffect(() => {
        const updateMousePosition = (e) => {
            setMousePosition({ x: e.clientX, y: e.clientY });
            // const timeoutId = setTimeout(() => {
            //     setMousePosition({ x: e.clientX, y: e.clientY });
            //   }, 100);
            // return () => clearTimeout(timeoutId);
        };

        window.addEventListener('mousemove', updateMousePosition);

        return () => window.removeEventListener('mousemove', updateMousePosition);
    }, []);
    
    useEffect(() => {
        const follower = followerRef.current;
        let position = { x: 0, y: 0 };
    
        const updateFollowerPosition = () => {
          const dx = mousePosition.x - position.x;
          const dy = mousePosition.y - position.y;
          const distance = Math.sqrt(dx * dx + dy * dy); // Calculate distance
          const speed = distance * .01; // Adjust factor for distance
    
          // Apply an ease-out effect based on the distance
          const ease = 1 - Math.exp(-speed);
    
          position.x += dx * ease;
          position.y += dy * ease;
    
          if (follower) {
            follower.style.left = position.x + 'px';
            follower.style.top = position.y + 'px';
          }
    
          requestAnimationFrame(updateFollowerPosition);
        };
    
        requestAnimationFrame(updateFollowerPosition);
    
        return () => cancelAnimationFrame(updateFollowerPosition);
      }, [mousePosition]);  
    const [followerClass, setFollowerClass] = useState('follower small peach')


    //  Home Testimonial
    const testimonials = [
        {
            performance:'Excellent',
            test_content:`Impressed by Trulle's custom ERP software, tailored to our needs. 
            Their SEO-integrated system has optimized our operations, enhancing efficiency and driving growth."`,
            test_by:'Suren',
            rating:4
        }
    ]
    const [homeTestIndex, setHomeTestIndex] = useState(0)
    const [testSlideMove, setTestSlideMove] = useState('translateX(0px)')
    const preHomeTest =()=>{
        if(homeTestIndex>0){
            setTestSlideMove(`translateX(-${340*(homeTestIndex-1)}px)`)
            setHomeTestIndex(homeTestIndex-1)
        }
    }
    const nextHomeTest =()=>{
        if(homeTestIndex<testimonials.length-1){
            setTestSlideMove(`translateX(-${340*(homeTestIndex+1)}px)`)
            setHomeTestIndex(homeTestIndex+1)
        }
    }

    // Review
    const [isLoadingReview, setIsLoadingReview] = useState(false)
    const [review, setReview] = useState({
        AppStatus:"webReview",
        name:"",
        mail:"",
        review:""
    })

    const reviewChange = (e)=>{
        const {name, value} = e.target
        setReview((preData)=>({
            ...preData,
            [name]:value
        }))
    }

    const reviewSubmit= async(e)=>{
        e.preventDefault()
        // https://script.google.com/macros/s/AKfycbwkPMV9myZqsKspVRvS3s9DqSJeaYlQqjbegTI4K5Alnvpr7K0XjmlVDKDpXoJhJ47ExQ/exec
        setIsLoadingReview(true)

        const today = new Date();
        const date = `${today.getDate()<10?"0"+today.getDate():today.getDate()}/${(today.getMonth()+1)<10?"0"+(today.getMonth()+1):(today.getMonth()+1)}/${today.getFullYear()}`

        const revData = new FormData();
        revData.append('AppStatus', review.AppStatus);
        revData.append('Data', date);
        revData.append('Name', review.name);
        revData.append('Mail', review.mail);
        revData.append('Review', review.review);

        axios.post('https://script.google.com/macros/s/AKfycbwkPMV9myZqsKspVRvS3s9DqSJeaYlQqjbegTI4K5Alnvpr7K0XjmlVDKDpXoJhJ47ExQ/exec', revData)
        .then(res=>{
            if (res.status===200){
                setIsLoadingReview(false)
                setReview({
                    AppStatus:"webReview",
                    name:"",
                    mail:"",
                    review:""
                })
            } else{
                console.error('Server returned an error status:', res.status);
            }
        })
        .catch(error=>{
            console.error('Error:',error);
        })
    }
    return(
        <>
            <div className='contactWrapper'>
                <section className='contactForm'>
                    <figure>
                        <img src='../images/contact.svg' alt='Contact Trulle'/>
                        <p>We are Here To Help Let’s Get In Touch</p>
                    </figure>
                    <form onSubmit={enqSubmit}>
                        <h2>Let’s Talk</h2>
                        <div className='contactInputContainer'>
                            <input
                                type='text'
                                name='name'
                                value={enq.name}
                                onChange={enqChange}
                                placeholder='Name'
                                required
                            />
                        </div>
                        <div className='contactInputContainer'>
                            <input
                                type='tel'
                                name='mobile'
                                value={enq.mobile}
                                onChange={enqChange}
                                placeholder='Mobile Number'
                                minLength={10}
                                maxLength={10}
                                pattern="[0-9]{10}"
                                required
                            />
                        </div>
                        <div className='contactInputContainer'>
                            <input
                                type='email'
                                name='email'
                                value={enq.email}
                                onChange={enqChange}
                                placeholder='Email'
                            />
                        </div>
                        <div className='contactInputContainer'>
                            <input
                                type='text'
                                name='location'
                                value={enq.location}
                                onChange={enqChange}
                                placeholder='Location'
                            />
                        </div>
                        <div className='contactInputContainer'>
                            <select
                                name='service'
                                value={enq.service}
                                onChange={enqChange}
                                required
                            >
                                <option value={""} hidden>Service</option>
                                <option value="web_development" >Web Development</option>
                                <option value="web_application" >Web Application</option>
                                <option value="app_development" >Web Development</option>
                                <option value="logo_design" >Logo Design</option>
                                <option value="brand_design" >Brand Design</option> 
                            </select>
                        </div>
                        <div className='contactSubmitContainer'>
                            <button type='Submit'>Submit
                                <img src='../images/shareLink.svg' alt=''/>
                            </button>
                        </div>
                    </form>
                </section>
                <section>
                    <div className='home_clients' onMouseEnter={()=>setFollowerClass('followerDifference white big')} onMouseLeave={()=>setFollowerClass('follower small peach')}>
                        <div className='home_client_container'>
                            <h1>Our Clients</h1>
                            <div className='slide_container'>
                                <div className='Client_slider'>
                                    <Marquee 
                                        children={<img src='./images/ss/strip1.svg' alt='Trulle Clients'/>} 
                                        speed={'198000ms'}
                                        // speed={'9000ms'}
                                        type={'strip'}/>                                
                                </div>
                                <div className='Client_slider'>
                                    <Marquee 
                                        children={<img src='./images/ss/strip2.svg' alt='Trulle Clients'/>} 
                                        speed={'132000ms'}
                                        // speed={'6000ms'}
                                        type={'strip'}/>
                                </div>
                                <div className='Client_slider'>
                                    <Marquee 
                                        children={<img src='./images/ss/strip3.svg' alt='Trulle Clients'/>} 
                                        speed={'66000ms'}
                                        // speed={'3000ms'}
                                        type={'strip'}/>
                                </div>
                            </div>      
                            <div className='smaller'>
                                <p>The world is smaller...</p>
                            </div>              
                        </div>                
                    </div>
                </section>
                <section style={{width:"70%", marginLeft:"15%"}}>
                    <div className='home_testimonial'>
                        <div className='testrow1'>
                            <header>
                                <h1 className='home_testimonial_title'>TESTIMONIAL</h1>
                            </header>
                            <div className='testimonial_keys'>
                                <div className='home__test_keys' onClick={preHomeTest}>
                                    <img src='./images/left.png' alt='trulle'/>
                                </div>
                                <div className='home__test_keys' onClick={nextHomeTest}>
                                    <img src='./images/right.png' alt='trulle'/>
                                </div>
                            </div>
                        </div>
                        <div className='home_testimonial_main'>
                            <div className='home_testimonial_slider' style={{width:`${testimonials.length*360}px`, transform:`${testSlideMove}`}}>
                                {testimonials.map((tests)=>{
                                    return(
                                        <Testimonial
                                            performance={tests.performance}
                                            test_content={tests.test_content}
                                            test_by={tests.test_by}
                                            rating={tests.rating}
                                        />
                                    )
                                })}
                            </div>                    
                        </div>   
                        {/* <div className='bhokeContainer'>
                            <div className='bhoketest'>
                                <Bhoke/>
                            </div>
                        </div>            */}
                    </div>
                </section>
                <section style={{width:"70%", marginLeft:"15%", height:"700px"}}>
                    <div id='map' className='home_map'>
                        <h1>Reach Us</h1>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.206318632924!2d79.13572207507626!3d12.95864578735556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bad4731b0ded535%3A0x253b3e5a516e42cd!2sTrulle!5e0!3m2!1sen!2sin!4v1717242832562!5m2!1sen!2sin" style={{width:"600",height:"450", border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </section>
                <section className='contactSection'>
                    <h1>Contact US</h1>
                    <div className='contactContainer'>
                        <div className='col1'>
                            <h3>Address</h3>
                            <p>No.43/A, East Gandhi Nagar,
                            Auxilium Road, Katpadi,
                            Vellore - 632006.</p>
                        </div>
                        <div className='col1'>
                            <div className='row'>
                                <h3>Phone</h3>
                                <p>7826099040, 9600580990</p>
                            </div>
                            <div className='row'>
                                <h3>Mail</h3>
                                <p>enquiry@trulle.in</p>
                            </div>
                        </div>
                        <form onSubmit={reviewSubmit}>
                            <div className='reviewInput'>
                                <input
                                    type='text'
                                    name='name'
                                    placeholder='Name'
                                    value={review.name}
                                    onChange={reviewChange}
                                />
                            </div>
                            <div className='reviewInput'>
                                <input
                                    type='mail'
                                    name='mail'
                                    placeholder='Mail'
                                    value={review.mail}
                                    onChange={reviewChange}
                                />
                            </div>
                            <div className='reviewInput'>
                                <textarea
                                    name='review'
                                    placeholder='Write or Review Us'
                                    value={review.review}
                                    onChange={reviewChange}
                                    rows={3}
                                />
                            </div>
                            <div className='reviewSubmit'>
                                <button
                                    type='submit' 
                                    disabled={isLoadingReview}                                   
                                >Submit</button>
                            </div>
                        </form>
                    </div>                    
                </section>
            </div>
            {isTouchDevice?null:<div className={followerClass} style={{ left: mousePosition.x-8, top: mousePosition.y-7 }}>
            {/* <div className={followerClass} ref={followerRef}> */}
            
            </div>}
            {showAlert && <CustomAlert message1='Thanks for Contacting Trulle.' message2=' Our Team will contact you soon...' onClose={handleCloseAlert} />}
        </>
    )
}