import { Link, useLocation } from 'react-router-dom'
import './footer.css'
export default function Footer (){
    const location = useLocation()
    return(
        <>
            <div className='footer'>
                {/* <h1>Contact Us</h1> */}
                <footer>
                <div className='footer_div'>
                    <div className='footer_container'>
                        <div className='footerLogo'>
                            <img src='./images/footerLogo.png' alt='Trulle'></img>
                        </div>
                        <div className='container_1'>
                            <h3>Address</h3>
                            <p>
                                No.43/A, East Gandhi Nagar,<br/>
                                Auxilium Road, <em>Katpadi</em>,<br/>
                                <em>Vellore</em> - 632006.
                            </p>
                        </div>
                        <div className='container_1'>
                            <h3>Phone</h3>
                            <p>7826 099 040</p>
                        </div>
                    </div>
                    <div className='footer_container'>
                        <div className='footer_menu'>
                            {location.pathname==="/"?<a href='#home'>Home</a>:<Link to='/'>Home</Link>}
                            <a href='#about'>About Us</a>
                            <a href='#service'>Service</a>
                            <a href='#portfolio'>Portfolio</a>
                            <Link to='/contact'>Contact Us</Link>
                        </div>
                    </div>
                    <div className='footer_container footer_width_social'>
                        <div className='footer_social'>
                            <h3>Follow Us On</h3>
                            <div className='social_icons'>
                                <div className='icon'>
                                    <img src='./images/footer/behance.png' alt='trulle behance'/>
                                </div>
                                <div className='icon'>
                                    <a href='https://www.linkedin.com/company/trulleinc/'>
                                        <img src='./images/footer/linkedin.png' alt='trulle linkedin'/>
                                    </a>
                                </div>
                                <div className='icon'>
                                    <a href='https://www.instagram.com/trulle.in?igsh=bThvaTR5Yzh0MXY0'>
                                        <img src='./images/footer/insta.png' alt='trulle insta'/>
                                    </a>
                                </div>
                                <div className='icon'>
                                    <img src='./images/footer/fb.png' alt='trulle fb'/>
                                </div>
                                <div className='icon'>
                                    <a href='http://wa.me/917826099040'>
                                        <img src='./images/whatsapp.svg' alt='trulle whatsapp'/>
                                    </a>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>   
                </footer>     
            </div>
            {/* <div className='footer_image_container'>
                <img src='./images/footer_image.png' className='footer_image'/>
            </div> */}
        </>
    )
}